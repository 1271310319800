import React from 'react';
import { bool, func } from 'prop-types';

import {
  Image,
  Box,
  Button,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { AiOutlineHeart } from 'react-icons/ai';

import useLang from '@hooks/useLang';
import txtWording from './locales';

import { BG_LOVESTORY } from '@/constants/assets';
import loveStoryData from '@/api/love-story';

function LoveStoryContent({ isOpen, onClose }) {
  const defaultLang = useLang();
  const lang = defaultLang;

  const renderIcon = (img) => {
    if (img) {
      return (
        <Image
          src={img}
          boxSize="50px"
          borderRadius="full"
          objectFit="cover"
          objectPosition="center"
        />
      );
    }

    return <AiOutlineHeart color="white" size="18px" />;
  };

  return (
    <Modal size="full" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent maxWidth="500px">
        <ModalCloseButton color="transparent" />
        <ModalBody bgImage={`url(${BG_LOVESTORY})`} bgSize="cover" bgPos="center">
          <Box paddingTop="32px">
            <Box bgColor="bgPrimary" borderRadius="16px" padding="16px">
              <Timeline
                lineColor="white"
                lineStyle={{ top: '20px', height: 'calc(100% - 60px)', left: '25px' }}
              >
                {loveStoryData.map((item, index) => {
                  return (
                    <TimelineEvent
                      key={`index-${index}`}
                      title={item.title}
                      titleStyle={{ color: 'white', fontWeight: 'bold', paddingLeft: '20px' }}
                      subtitleStyle={{ color: 'white' }}
                      cardHeaderStyle={{ color: 'white' }}
                      icon={renderIcon(item.img)}
                      iconStyle={{ width: '50px', height: '50px' }}
                      bubbleStyle={{
                        width: '50px',
                        height: '50px',
                        border: 'none',
                        backgroundColor: 'rgb(200 103 53)',
                      }}
                      contentStyle={{
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        padding: '0',
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: '400',
                        paddingLeft: '20px',
                        marginTop: '8px',
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: item.description }} />
                    </TimelineEvent>
                  );
                })}
              </Timeline>
            </Box>
          </Box>
          <Center>
            <Button
              size="sm"
              fontWeight="normal"
              margin="16px 0"
              color="mainColorText"
              bgColor="bgPrimary"
              onClick={() => onClose()}
            >
              {txtWording.backContent[lang]}
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

LoveStoryContent.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default LoveStoryContent;
